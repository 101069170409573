

button {
  border-radius: 4px;
}

.img-render {
  width: 500px;
  border-radius: 4px;
}

@font-face {
	font-family: 'Circular Std';
	src: url('./circular-std-medium-500.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

body {
	font-family: 'Circular Std', sans-serif;
}
