

.black-header {
  background-color: rgb(19 19 19 / 1);
  width: 100%;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  padding: 10px;
}

html,body, #root {
  height: -webkit-fill-available;
}

.sidebar {
  background-color: rgb(19 19 19 / 1);
  width: 250px;
  min-width: 250px;
}



.primary-button {
  background-size: 100% 100%;
  border-radius: 4px;
  border: 2px solid transparent;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  padding: 5px;
  color: #fff; 
}

.primary-button:hover {
  background: #ffffff2b;
}

.container-modal {
  border-radius: 10px;
}

.container-create {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1e1e1e;
}

.w-fill-available {
  width: -webkit-fill-available;
}

.text-caption {
  width: 160px;
  display: block;
  color: #fff;
  font-size: 10px;
}

.save-button {
  background: #000;
  background-size: 100% 100%;
  border-radius: 4px;
  border: 2px solid transparent;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  padding: 5px;
  color: #fff; 
}

.image-loading {
 min-width: 520px;
 width: 520px;
}

.save-button:hover {
 opacity: 0.7;
}

textarea {
  outline: none;
  font-weight: bold;
  background: transparent;
  border: none !important;
  min-width: 500px;
}

.input-container  {
  opacity: 1;
  position: relative;
  background: #000000;
  border-radius: 4px;
  overflow: hidden;
  min-height: 130px;
  padding-left: 10px;
  padding-right: 10px;
}

.loading-button {
  background-size: 100% 100%;
  border-radius: 4px;
  border: 2px solid transparent;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  padding: 10px ;
  font-weight: bold;
  color: #1c222b;
}

.side-color {
  background-color: rgb(19 19 19 / 1);
}
.last-images {
width: 65px;
height: 65px;
}

.engine {
  background-color: rgba(38, 38, 38, .5);
  padding: 4px;
  border-radius: 10px;
}

.kinera-title {
  font-size: 22px;
  color: #FAFAFA;
 
}

.overlay {
  z-index: 999999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #232224bd;
}



.btn {
  border: none;
  padding: 5px 10px;
  background: transparent;
  border-radius: 4px;
  font-family: Inter, Roobert, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  white-space: nowrap;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  &:disabled {
    background-color: #ededed;
    cursor: not-allowed; /* Adicional para indicar que o botão está desabilitado */
    color: white; /* Se quiser mudar a cor do texto também */
    border-color: transparent; /* Para remover a borda, se necessário */
  }
  
}

.title {
  font-size: 30px;
}

.back-drop {
  background-color: #191c32;
  opacity: 0.4;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.page-body {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.overflow-container {
  max-height: 300px;
  overflow-y: auto;
  height: 300px;
  border-bottom: solid 1px #ededed3d;

  /* WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 2px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ededed; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Optional: Rounded corners for the scrollbar thumb */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Background of the scrollbar track */
  }

  /* Firefox */
  scrollbar-width: thin; /* Makes the scrollbar thin */
  scrollbar-color: #ededed transparent; /* Thumb color and track color */
}

.modal-container {
  height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.border-color {
  border:  solid 1px rgb(38 38 38/1);
}

.left-modal {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

}

.right-modal {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.card-info {
  width: 148px;
  //background: #2c2c2c;
  padding: 10px;
  border-radius: 4px;
}

.label {
  padding: 4px 0px;
  color: #6f6f6f;
  font-size: 15px;
  font-weight: 300;
}

.data__initial {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ccc; /* Cor de fundo para o círculo */
  font-size: 24px; /* Tamanho da fonte para a inicial */
  font-weight: bold; /* Negrito para a inicial */
  color: #fff; /* Cor do texto */
}

.input {
  display: block;
  border-radius: 4px;
  color: #000;
  border: 1px solid #DEDEDE;
  width: max-content;
  box-sizing: border-box;
  padding: 3px 10px;
  width: 100%;
  background: #d0c3c39c;
  font-family: inherit;
  span {
    color: #000;
  }
}

.container-stream {
  .peer-video-share{
    position: relative;
  }
  
  .is-presenter {
    position: absolute;
    z-index: 10;
    max-width: 200px !important;
    top: 0;
    left: 0;
  }

  .video-cover {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #191B23;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
}

.meet-container {
  .peer-video {
    height: 250px;
    width: 450px;
    border-radius: 0.5rem;
    object-fit: cover;
    margin: 10px;
    border: 1px solid #191B23;
  }
  
  .video-cover {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #191B23;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fill-available {
  width: -webkit-fill-available;
}

.modal {
  padding: 10px;
}

.modal-small {
  min-width: 360px;
  width: 360px;
}

.modal-medium {
  min-width: 580px;
  width: 580px;
}

.border-default {
  border: solid 2px #ededed;
}

.header {
  background: #f8f2f2;
}

.container {
  background: #f8f2f2;
  border-radius: 4px;
}

body {
  background: #ffff;
 // padding: 20px;
}

.primary {
  color: #fff;
  font-weight: bold;
  background: #2c2c2c;
}

.control-bar-meet {
  gap: 8px;
  display: flex;
  bottom: 0;
  padding: 15px;
  justify-content: center;
  z-index: 1;

  button{
    &:hover {
      background: #b5b5b5;
      color: #fff;
      font-weight: bold;
      border: none;
    }
  }
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000c9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.divider {
  background: #e1dada;
  border: solid 1px #e1dada;
}

.subtle {
  color: #000;
  font-weight: bold;
  &:hover {
    background: #ffffff10;
  }
}
.mono {
  font-family: monospace;
  font-weight: 600;
}

.default {
  background: rgba(173, 173, 184, 0.22);
  color: #0e0e10;
  font-weight: bold;
}

.fit-height {
  height: -webkit-fill-available;
}

.default-text {
  color: #000;
 // width: 500px;
}

.text-container {
  padding: 4px;
  background: #e9e9e9;
  border-radius: 4px;
  .label {
    padding: 4px 0px;
    color: #6f6f6f;
    font-size: 15px;
    font-weight: 300;
  }
  .text {
    color: #000;
  }
}


.loader-dots div {
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader-dots div:nth-child(1) {
	left: 8px;
	animation: loader-dots1 0.6s infinite;
}
.loader-dots div:nth-child(2) {
	left: 8px;
	animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(3) {
	left: 32px;
	animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(4) {
	left: 56px;
	animation: loader-dots3 0.6s infinite;
}
@keyframes loader-dots1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes loader-dots3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes loader-dots2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}

.caption-text {
  font-size: 12px;
  color: #fff;
}

.options {
  //background-color: #1e1e1e;
  //padding: 20px;
  border-radius: 8px;
  width: 200px;
  color: white;
  font-family: Arial, sans-serif;

  .option-item {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      color: #cccccc;
    }

    input[type="range"] {
      -webkit-appearance: none; /* Removes default styling */
      width: 100%;
      height: 2px; /* Custom height */
      background: #e9e9e9; /* Custom background color */
      outline: none;
      opacity: 0.7;
      transition: opacity 0.2s;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #4CAF50; /* Thumb color */
        cursor: pointer;
      }

      &::-moz-range-thumb {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #4CAF50; /* Thumb color */
        cursor: pointer;
      }

      &::-ms-thumb {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #4CAF50; /* Thumb color */
        cursor: pointer;
      }

      &:hover {
        opacity: 1; /* Increase opacity on hover */
      }
    }

    span {
      display: block;
      text-align: right;
      font-size: 14px;
      color: #ffffff;
    }
  }
}